import React, { useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import ENV from "utils/helpers/env";
import { NAV_LINKS } from "views/landing/components/PGLandingNavbar";

const LandingPageHeader = ({ className = "" }) => {
  const [expanded, setExpanded] = useState(false);

  const navigator = useNavigate();
  const login = () => {
    navigator("/auth/login");
  };
  return (
    <Navbar
      sticky="top"
      key="lg"
      expand="lg"
      expanded={expanded}
      className={`navbar-bg thick-font ${className ?? ""}`}
    >
      <Container className="landing-header">
        <Navbar.Brand as={Link} to="/" className="p-0">
          <img
            className="logo"
            src="/assets/images/global-images/logo.png"
            alt="logo"
          />
        </Navbar.Brand>
        <div className="create-join-sub">
          <a className="sub-menu" href="/auth/login">
            Create
          </a>
          <a className="sub-menu" href="/join">
            Join
          </a>
        </div>

        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-lg"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="end"
        >
          <Offcanvas.Header closeButton onHide={() => setExpanded(false)}>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
              <Link to="/" onClick={() => setExpanded(false)}>
                <img
                  className="logo"
                  src="/assets/images/global-images/logo.png"
                  alt="logo"
                />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="d-block d-xl-none list-unstyled p-3">
              {NAV_LINKS.left
                .filter((i) => i.label)
                .map((link, index) => (
                  <li
                    key={index}
                    onClick={() => setExpanded(false)}
                    className="mb-3"
                  >
                    <Link
                      to={
                        link.link.startsWith("#") ? "/" + link.link : link.link
                      }
                      target={link.link.startsWith("#") ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              {NAV_LINKS.right
                .filter((i) => i.label)
                .map((link, index) => (
                  <li
                    key={index}
                    onClick={() => setExpanded(false)}
                    className="mb-3"
                  >
                    <Link
                      to={
                        link.link.startsWith("#") ? "/" + link.link : link.link
                      }
                      target={link.link.startsWith("#") ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
            </ul>
            <button className="signUp signUp-desktop" onClick={login}>
              Sign Up or Login
            </button>
            <div className="button-box">
              {ENV.WL_APP_SHORT_URL ? (
                <button
                  className="download-button mb-3"
                  onClick={() => {
                    window.open(ENV.WL_APP_SHORT_URL, "_blank");
                  }}
                >
                  Download {ENV.WL_BUSINESS_NAME} App
                </button>
              ) : null}
              <button className="signUp" onClick={login}>
                Sign Up or Login
              </button>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default LandingPageHeader;
