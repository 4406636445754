import classNames from "classnames";
import React, { useState, useEffect } from "react";
import ENV from "utils/helpers/env";
import PoweredBy from "views/landing/components/PoweredBy";

const AuthTextLeft = (props) => {
  // const isNotMobile = window.innerWidth > 900;
  const [isNotMobile, setIsNotMobile] = useState(window.innerWidth > 900);
  const {
    class: className,
    image = ENV.WL_LOGIN_BANNER,
    text = (
      <div>
        <p className="m-0">“{ENV.WL_LOGIN_QUOTE}”</p>
      </div>
    ),
    width = isNotMobile ? "520px" : "410px",
    logo = "/assets/images/global-images/logo_colored_white.png",
  } = props;

  const handleResize = () => {
    if (window.innerWidth > 900) {
      setIsNotMobile(true);
    } else {
      setIsNotMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div className={classNames(className, "position-relative")}>
      <div className="authTextLeft">
        <img src={image} alt="banner" />
        <div className="signup-logo-wrapper">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div style={{ width }} className="overlayText">
          {text}
        </div>
      </div>
      <PoweredBy centered={true} />
    </div>
  );
};

export default AuthTextLeft;
