import useLogoutHandler from "hooks/useLogoutHandler";
import React, { useEffect, useRef } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  SEND_USER_CLICK_ANALYTICS,
  GET_USER_DETAILS,
} from "redux-store/sagas/saga-actions";
import MenuDrawer from "../sidebar/MenuDrawer";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../loader/Spinner";
import { setLoading } from "../../../redux-store/slices/auth";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import SubscriptionModal from "views/subscription";
import ENV, { currentUserIsOwner } from "utils/helpers/env";
import { toast } from "react-toastify";
import Notifications from "./Notifications";
import ANALYTICS from "constants/analyticsKeys";

const DefaultHeader = (props) => {
  const hasAccessToFeature = useSubscription().hasAccessToFeature(
    PLAN_FEATURES.view_analytics
  );
  const dispatch = useDispatch();
  const { logoutHandler } = useLogoutHandler();
  const {
    userProfileDetails: { userType, name, lastName, avatar },
  } = useSelector((state) => state.settings);
  const userName = (name || "User") + " " + (lastName || "");
  const isPhotographer = userType === "PHOTOGRAPHER";
  const { loading } = useSelector((state) => state.auth);
  const [subscriptionModal, setSubscriptionModal] = React.useState(false);

  const handleLogoutClick = () => {
    dispatch(setLoading(true));
    logoutHandler();
  };

  const fetchCount = useRef({
    count: 0,
  });

  useEffect(() => {
    if (!name && fetchCount.current.count !== 1) {
      dispatch({ type: GET_USER_DETAILS });
      fetchCount.current.count = 1;
    }
  }, []);

  const navigate = useNavigate();

  const handleAnalyticsNav = (e) => {
    e.preventDefault();
    if (hasAccessToFeature) {
      navigate("/analytics");
    } else if (currentUserIsOwner()) {
      setSubscriptionModal(true);
    } else {
      toast.error("You don't have access to this feature. Please subscribe.");
    }
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.ANALYTICS] },
    });
  };
  return (
    <>
      <Spinner loading={loading} />
      <div className="defaultHeader">
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container className="position-relative max-w-full">
            {props.goBack && (
              <Navbar.Brand
                className="back-button-wrapper"
                as={Link}
                to={typeof props.goBack === "string" ? props.goBack : "/groups"}
              >
                <img
                  width="16px"
                  src="/assets/images/icons/back-arrow.png"
                  alt="logo"
                  className="back-button"
                />
              </Navbar.Brand>
            )}
            <Navbar.Brand as={Link} to="/groups" className="p-0">
              <img
                className="logo"
                src="/assets/images/global-images/logo.png"
                alt="logo"
              />
            </Navbar.Brand>

            <Notifications className="ms-auto" />
            <MenuDrawer handleAnalyticsNav={handleAnalyticsNav} />
            <Navbar.Collapse className="flex-grow-0" id="responsive-navbar-nav">
              <Nav>
                <NavDropdown
                  align="end"
                  title={
                    <div className="header-user">
                      <div className="header-thumb">
                        <img
                          className="thumbnail-image"
                          src={avatar || "/assets/images/icons/user.png"}
                          alt="user pic"
                        />
                      </div>
                      {userName}
                      <img
                        className="menu-arrow"
                        src="/assets/images/icons/arrow-down.png"
                        alt="user pic"
                      />
                    </div>
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    onClick={() => {
                      isPhotographer &&
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                        });
                    }}
                    to="/profile-settings/profile"
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/setting.png"
                      alt="user pic"
                    />
                    {isPhotographer ? "Business Settings" : "Profile Settings"}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleAnalyticsNav}>
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/level.png"
                      alt="user pic"
                    />
                    Analytics
                  </NavDropdown.Item>
                  {!ENV.WL_HIDE_HELPDESK && (
                    <NavDropdown.Item
                      href="https://www.kwikpic.in/helpdesk"
                      target="_blank"
                      onClick={() => {
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                        });
                      }}
                    >
                      <img
                        className="thumbnail-image"
                        src="/assets/images/icons/infoq.png"
                        alt="user pic"
                      />
                      Help & Support
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item as={Link} to="/privacy-security">
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/privacy-security/privacy.png"
                      alt="security icon"
                    />
                    Privacy and Security
                  </NavDropdown.Item>
                  {!ENV.WL_HIDE_TUTORIAL && (
                    <NavDropdown.Item
                      href={
                        userType === "USER"
                          ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                          : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.TUTORIAL] },
                        })
                      }
                    >
                      <img
                        className="thumbnail-image"
                        src="/assets/images/icons/youtube-tut.png"
                        alt="tutorial"
                      />
                      Tutorial
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item as={Link} to="/#about">
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/info.png"
                      alt="user pic"
                    />
                    About
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogoutClick}>
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/download.png"
                      alt="user pic"
                    />
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {subscriptionModal && (
        <SubscriptionModal
          isOpen={subscriptionModal}
          onClose={() => setSubscriptionModal(false)}
        />
      )}
    </>
  );
};

export default DefaultHeader;
