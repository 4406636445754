import React from "react";
import OutlinedButton from "views/components/button/OutlinedButton";
import DefaultButton from "views/components/button/DefaultButton";
import { Link } from "react-router-dom";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import css from "../css/Landing.module.css";
import ENV from "utils/helpers/env";

export const NAV_LINKS = {
  left: [
    {
      label: ENV.WL_LINK_1_LABEL,
      link: ENV.WL_LINK_1_URL,
    },
    {
      label: ENV.WL_LINK_2_LABEL,
      link: ENV.WL_LINK_2_URL,
    },
    {
      label: ENV.WL_LINK_3_LABEL,
      link: ENV.WL_LINK_3_URL,
    },
  ],
  center: {
    light: "/assets/images/global-images/logo-white.png",
    dark: "/assets/images/global-images/logo.png",
    link: "/",
  },
  right: [
    {
      label: ENV.WL_LINK_4_LABEL,
      link: ENV.WL_LINK_4_URL,
    },
    {
      label: ENV.WL_LINK_5_LABEL,
      link: ENV.WL_LINK_5_URL,
    },
    {
      label: ENV.WL_LINK_6_LABEL,
      link: ENV.WL_LINK_6_URL,
    },
  ],
};

const PGLandingNavbar = () => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Navbar expand="xl" expanded={expanded}>
      <Container fluid>
        <Navbar.Brand className="d-block d-xl-none">
          <Link to={NAV_LINKS.center.link}>
            <img src={NAV_LINKS.center.light} alt="logo" className={css.logo} />
          </Link>
        </Navbar.Brand>
        <ul className={css.nav}>
          {NAV_LINKS.left
            .filter((i) => i.label)
            .map((link, index) => (
              <li key={index}>
                <a
                  href={link.link}
                  target={link.link.startsWith("#") ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                >
                  {link.label}
                </a>
              </li>
            ))}
          <li>
            <Navbar.Brand className="m-0">
              <img
                src={NAV_LINKS.center.light}
                alt="logo"
                className={css.logo}
              />
            </Navbar.Brand>
          </li>
          {NAV_LINKS.right
            .filter((i) => i.label)
            .map((link, index) => (
              <li key={index}>
                <a
                  href={link.link}
                  target={link.link.startsWith("#") ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                >
                  {link.label}
                </a>
              </li>
            ))}
        </ul>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-xl"
          onClick={() => setExpanded((p) => !p)}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-xl ocnv"
          aria-labelledby="offcanvasNavbarLabel-expand-xl"
          placement="end"
          data-bs-scroll="true"
          restoreFocus={false}
          onHide={() => setExpanded(false)}
        >
          <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-xl">
              <img src={NAV_LINKS.center.dark} alt="logo" className="logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="d-block d-xl-none list-unstyled p-3">
              {NAV_LINKS.left
                .filter((i) => i.label)
                .map((link, index) => (
                  <li
                    key={index}
                    onClick={() => setExpanded(false)}
                    className="mb-3"
                  >
                    <a
                      href={link.link}
                      target={link.link.startsWith("#") ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              {NAV_LINKS.right
                .filter((i) => i.label)
                .map((link, index) => (
                  <li
                    key={index}
                    onClick={() => setExpanded(false)}
                    className="mb-3"
                  >
                    <a
                      href={link.link}
                      target={link.link.startsWith("#") ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
            </ul>
            <div className="button-box">
              {ENV.WL_APP_SHORT_URL ? (
                <OutlinedButton
                  title={`Download ${ENV.WL_BUSINESS_NAME} App`}
                  onClick={() => window.open(ENV.WL_APP_SHORT_URL)}
                  className="w-100 mb-3"
                  variant="sm"
                />
              ) : null}

              <DefaultButton
                variant="sm"
                className="font-15"
                title="Sign Up or Login"
                onClick={() => window.open("/auth/login")}
              />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default PGLandingNavbar;
