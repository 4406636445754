import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import "./styles/gallary.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_GROUP_DESIGNA_AND_SETTINGS,
  GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
} from "redux-store/sagas/saga-actions";
import { setGroupSettingsLoader } from "redux-store/slices/settings";
import { Modal } from "react-bootstrap";
import { setUserFirstTime } from "redux-store/slices/user";
import "./styles/gallary.css";
import useSubscription from "views/subscription/hooks/useSubscription";
import { getLocalStorageUser } from "utils/helpers/localstorage";

export const allImagesType = {
  DATE: "DATE",
  UPLOAD: "UPLOAD",
  UNIDENTIFIED: "UNIDENTIFIED",
  MONTH: "MONTH",
  YEAR: "YEAR",
};

const GalleryRoute = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { firstTime } = useSelector((state) => state.user);

  const groupId = params.get("groupId");

  const { getUserAnalyticsAPI } = useSubscription();

  useEffect(() => {
    if (!groupId || groupId === "null") return;

    dispatch(setGroupSettingsLoader(true));
    getUserAnalyticsAPI();
    dispatch({
      type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
      payload: { groupId, sendAll: false },
    });
    dispatch({
      type: GET_GROUP_DESIGNA_AND_SETTINGS,
      groupId,
    });

    const timeout = setTimeout(() => {
      if (firstTime) dispatch(setUserFirstTime(false));
    }, 4000);

    return () => clearTimeout(timeout);
  }, [groupId]);

  if (!groupId || groupId === "null") return <Navigate to="/groups" replace />;

  return (
    <ProtectedRoute>
      <Outlet />
      <MyVerticallyCenteredModal
        show={firstTime}
        onHide={() => dispatch(setUserFirstTime(false))}
      />
    </ProtectedRoute>
  );
};

function MyVerticallyCenteredModal(props) {
  const { groupDetails, brandingData } = useSelector((state) => state.settings);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const localUser = getLocalStorageUser();
    setUser(localUser);
  }, [groupDetails]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center d-flex align-items-center flex-column justify-content-center p-4">
        <div className="mb-2 model-title">
          {`Joined "${groupDetails.name}"`}
        </div>
        <div className="mb-5">
          {groupDetails.isPrivate ? (
            <div className="model-group-type">Private Group</div>
          ) : (
            <div className="model-group-type">Public Group</div>
          )}
        </div>
        {brandingData?.business_name ? (
          <div className="mb-2 model-group-created-by">
            {`Album by ${brandingData?.business_name}`}
          </div>
        ) : null}
        {user?.name && (
          <>
            <div className="mb-4 model-group-type">as</div>
            <div className="model-box-name d-flex align-items-center flex-column justify-content-center">
              <span className="model-joined-user">
                {user?.avatar && (
                  <img
                    className="thumbnail-image h-30 aspect-square rounded-full"
                    style={{ marginRight: "10px" }}
                    src={user?.avatar}
                    alt="avatar"
                  />
                )}
                {`${user?.name} ${user?.lastName}`}
              </span>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default GalleryRoute;
